import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";

const CorporatesOrders = ({ CorporateOrder }) => {
  <Helmet>
    <meta charset="utf-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <link
      rel="icon"
      href="./Images/balaji.png"
      alt="/balaji"
      type="image/png"
      sizes="48x48"
    />
    <title>
      Contact for corporate Orders of earbuds,headphones,cables,chargers,usb
      cables manufacturer in Ahemedabad,Gujarat.
    </title>
    <meta
      name="description"
      content=" Give a Oders for Manufacturer of SILICON CABLE 6A 65W,CAR CHARGER PD+QC,EAR BUDS - W901 offered by Balaji manufacturing company, Ahmedabad, Gujarat"
    />
    <meta
      name="keywords"
      content="earbuds manufacturer|earbuds india|earphone manufacturers in india|best earbud manufacturers|Customized earbuds manufacturer company in Ahmedabad|Gujarat|give order for earbuds manufacturer company in Ahmedabad|Bulk order for charger|Bulk order for cables|Bulk order for charger|order for earbuds|Wholesale Orders earbuds|Top listed earbuds|EAR BUDS- B93|Balaji manufacturer company in Ahmedabad "
    />
    <link
      rel="canonical"
      href="https://balajimanufacturing.in/CorporateOrder"
    />

    <meta property="og:locale" content="en_us" />
    <script type="application/ld+json">
      {`
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": "Balaji Manufacturing Company",
    "image": "https://balajimanufacturing.in/Images/balaji.png",
    "@id": "https://balajimanufacturing.in/",
    "url": "https://balajimanufacturing.in/",
    "telephone": "+91 8733918223",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "E/4 vishwakarma society nr kameshwar school jodhpur cross road satellite Ahmedabad 380058",
      "addressLocality": "Ahmedabad",
      "postalCode": "380058",
      "addressCountry": "IN"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": 23.0275838,
      "longitude": 72.5216844
    },
    "openingHoursSpecification": {
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday"
    ],
    "opens": "00:00",
    "closes": "23:59"
  },
  "sameAs": [
    "https://www.facebook.com/profile.php?id=61559858167984",
    "https://www.instagram.com/balajicompany9"
  ] 
`}
    </script>
  </Helmet>;

  const initialFormData = {
    name: "",
    phone: "",
    subject: "",
    message: "",
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleWhatsAppClick = () => {
    const { name, Comapnyname, phone, Address } = formData;
    const whatsappMessage = `
    Name: ${name}
    Phone: ${phone}
    Comapnyname: ${Comapnyname}
    Address: ${Address}`;
    const whatsappLink = `https://wa.me/+918733918223?text=${encodeURIComponent(
      whatsappMessage
    )}`;
    window.open(whatsappLink, "_blank");
  };

  const [showForm, setShowForm] = useState(false);
  const formRef = useRef(null);

  const handleAddToWhatsApp = () => {
    const phoneNumber = "918733918223";
    const message = "Hello! Can I Get More Information About Your Business!";
    const encodedMessage = encodeURIComponent(message);

    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

    window.location.href = whatsappUrl;
  };

  const handleSubmitRequirement = () => {
    setShowForm(true);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (formRef.current && !formRef.current.contains(event.target)) {
        setShowForm(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="position-relative">
      <div className="col-12 position-relative bg-light coroporateimg">
        <img
          src="./Images/bannercontact.jpeg "
          alt="/bannercontact"
          className="w-100"
          style={{ height: "650px" }}
        />
        <div
          className="position-absolute fw-bold top-50 start-50 translate-middle text-center text-dark pt-5"
          style={{
            zIndex: 1,
            backgroundColor: "white",
            width: "100%",
            height: "100%",
            opacity: "0.9",
          }}
        >
          <div className="d-none d-md-block" style={{ marginTop: "150px" }}>
            <h1 style={{ fontSize: "2rem" }}>
              <b>Your Bulk Orders</b>
            </h1>
            <h2 className="fs-4">Are Now Hassle Free</h2>
            <p style={{ fontSize: "1.5rem" }}>
              <b>Come sail with us</b>
            </p>
            <button
              className="p-2 bg-danger text-white border rounded"
              onClick={handleSubmitRequirement}
            >
              Submit Your Requirement
            </button>
          </div>
          <div className="d-md-none d-sm-block">
            <h1 style={{ fontSize: "2rem" }}>
              <b>Your Bulk Orders</b>
            </h1>
            <h2 className="fs-4">Are Now Hassle Free</h2>
            <p style={{ fontSize: "1.5rem" }}>
              <b>Come sail with us</b>
            </p>
            <button
              className="p-2 bg-danger text-white border rounded"
              onClick={handleSubmitRequirement}
            >
              Submit Your Requirement
            </button>
          </div>
        </div>
      </div>

      {showForm && (
        <div
          ref={formRef}
          className="position-absolute start-50 translate-middle bg-white p-5 shadow"
          style={{ zIndex: 2, top: "250px", width: "300px" }}
        >
          <form onSubmit={handleWhatsAppClick}>
            <div className="row">
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Your name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Your Phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="row">
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Your Subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <textarea
                  className="form-control"
                  placeholder="Your Message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <button
                type="submit"
                className="p-2 bg-danger border-0 text-light rounded mt-3"
              >
                Send Message
              </button>
            </div>
          </form>
        </div>
      )}

      <div className="d-flex justify-content-between mx-5 mt-2">
        <h3 className="text-dark p-2" style={{ fontSize: "24px" }}>
          Got A{" "}
          <span>
            <b>
              bu
              <span className="border-bottom  border-danger border-5">ds</span>
            </b>
          </span>
        </h3>
      </div>

      <div
        className="d-flex justify-content-center"
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        {CorporateOrder.map((item, index) => (
          <div key={index}>
            <div className="card m-3" style={{ width: "19rem" }}>
              <div className="position-relative">
                <label
                  className="bg-warning text-dark rounded mx-5 p-1 px-2"
                  style={{
                    position: "absolute",
                    bottom: "0",
                    left: "10%",
                    transform: "translate(0%, 40%)",
                  }}
                >
                  {item.label}
                </label>
                <img src={item.img} className="card-img-top" alt={item.alt} />
              </div>
              <div className="card-body">
                <div className="d-flex justify-content-center gap-5">
                  <div className="text-left">
                    <h4 className="card-title mt-2 fs-5">
                      <b>{item.name}</b>
                    </h4>
                  </div>
                </div>
                <div className=""></div>
                <div className="pt-2">
                  <button
                    className="best btn border border-danger btn w-100 mt-2"
                    onClick={handleAddToWhatsApp}
                    style={{ outline: "none" }}
                  >
                    Shop Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CorporatesOrders;
