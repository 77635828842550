import React from "react";

const NewLounch = ({ newlounch }) => {
  const handleWhatsAppClick = () => {
    const phoneNumber = "918733918223";
    const message = "Hello! Can I Get More Information About Your Business!";
    const encodedMessage = encodeURIComponent(message);

    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

    window.location.href = whatsappUrl;
  };

  return (
    <div className="container-fluid p-5">
      <div className="d-flex justify-content-between mb-4">
        <h3 className="text-dark" style={{ fontSize: "30px" }}>
          New{" "}
          <span>
            <b>
              Launch
              <span className="border-bottom  border-danger border-5">es</span>
            </b>
          </span>
        </h3>
      </div>
      <div className="row justify-content-center">
        {newlounch.map((item, index) => (
          <div key={index} className="col-lg-4 col-md-4 col-sm-6 col-12 mb-3">
            <div
              className="card bg-light border border-0"
              style={{ boxShadow: "0px 0px 5px grey" }}
            >
              <img
                src={item.img}
                className="card-img-top zoom newlaunchcard"
                alt="Product"
                height="400px"
                // width="100px"
                width="auto"
              />
              <div className="card-body text-center">
                <p className="card-title fs-5">{item.name}</p>
                <p>{item.description}</p>

                <button
                  className="btn btn-danger btn-block "
                  onClick={handleWhatsAppClick}
                >
                  Shop Now
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NewLounch;
