import React from "react";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  <Helmet>
    <meta charset="utf-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <link
      rel="icon"
      href="./Images/balaji.png"
      alt="/balaji"
      type="image/png"
      sizes="48x48"
    />
    <title>
      About earbuds,charger,cables,companies in Ahemedabad,Gujarat,India.
    </title>
    <meta
      name="description"
      content="Balaji Manufacturing Company,jodhpur cross road satellite, Ahmedabad, Gujarat - Manufacturer of Quick Mobile Charger, Data Cable and Earbuds "
    />
    <meta
      name="keywords"
      content="earbuds manufacturer|earbuds india|earphone manufacturers in india|best earbud manufacturers|Balaji manufacturer company in Ahmedabad|Gujarat|India|About earbuds manufacturer company in Ahmedabad|Cables manufacturer company near satellite|Best Charger manufacturer company in Ahmedabad|Earbuds manufacturer company in Ahmedabad|List of earbuds manufacturer company Ahmedabad|Manufacturer company in Ahmedabad|Charger manufacturer in ahmedabad"
    />

    <link rel="canonical" href="https://balajimanufacturing.in/about-us" />

    <meta property="og:locale" content="en_us" />
    <script type="application/ld+json">
      {`
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": "Balaji Manufacturing Company",
    "image": "https://balajimanufacturing.in/Images/balaji.png",
    "@id": "https://balajimanufacturing.in/",
    "url": "https://balajimanufacturing.in/",
    "telephone": "+91 8733918223",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "E/4 vishwakarma society nr kameshwar school jodhpur cross road satellite Ahmedabad 380058",
      "addressLocality": "Ahmedabad",
      "postalCode": "380058",
      "addressCountry": "IN"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": 23.0275838,
      "longitude": 72.5216844
    },
    "openingHoursSpecification": {
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday"
    ],
    "opens": "00:00",
    "closes": "23:59"
  },
  "sameAs": [
    "https://www.facebook.com/profile.php?id=61559858167984",
    "https://www.instagram.com/balajicompany9"
  ] 
`}
    </script>
  </Helmet>;

  return (
    <div className="container-fluid">
      <div className="container-fluid row d-none d-md-block  d-md-flex justify-content-center mt-5 px-5">
        <div className="col-12 col-md-6 px-5">
          <img
            src="./Images/aboutimg.png"
            className="img-fluid"
            height="500px"
            alt="/aboutimg"
          />
        </div>
        <div className="col-12 col-md-6 px-5 p-2">
          <div className=" text-left ">
            <h1 className="text-left fs-5 mb-4">
              Why Choose <b>Balaji Manufacturing Company</b> Earbuds?
            </h1>
            <div style={{ fontSize: "16px" }}>
              <p>
                <b> Unrivaled Sound Quality:</b> Our earbuds are engineered to
                deliver crystal-clear sound with deep bass and crisp highs,
                allowing you to immerse yourself in your favorite music like
                never before.
              </p>
              <p>
                <b>Comfort and Durability:</b> Designed for all-day comfort, our
                earbuds feature ergonomic designs and soft silicone ear tips
                that ensure a secure fit. Plus, with premium materials and
                durable construction, they're built to withstand the rigors of
                daily use.
              </p>
              <p>
                <b>Seamless Connectivity:</b> Say goodbye to tangled wires and
                complicated setups. Our earbuds offer seamless Bluetooth
                connectivity, allowing you to pair effortlessly with your
                devices and enjoy wireless freedom.
              </p>
              <p>
                <b>Long-Lasting Battery Life:</b> Whether you're commuting,
                working out, or just relaxing at home, our earbuds provide hours
                of uninterrupted listening pleasure on a single charge, so you
                can keep the music playing all day long.
              </p>
              <p>
                <b>Sleek and Stylish Design:</b> With sleek, modern designs and
                a range of stylish colors to choose from, our earbuds are as
                fashionable as they are functional, making a statement wherever
                you go.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row  d-sm-block d-md-none d-flex justify-content-center mt-2 p-3">
        <div className="col-12 col-md-6 ">
          <img
            src="./Images/aboutimg.png"
            className="img-fluid"
            height="500px"
            alt="/aboutimg"
          />
        </div>
        <div className="col-12 col-md-6  p-2 mt-4">
          <div className=" text-left ">
            <h1 className="text-left fs-5 mb-4">
              Why Choose <b>Balaji Manufacturing Company</b> Earbuds?
            </h1>
            <div style={{ fontSize: "16px" }}>
              <p>
                <b> Unrivaled Sound Quality:</b> Our earbuds are engineered to
                deliver crystal-clear sound with deep bass and crisp highs,
                allowing you to immerse yourself in your favorite music like
                never before.
              </p>
              <p>
                <b>Comfort and Durability:</b> Designed for all-day comfort, our
                earbuds feature ergonomic designs and soft silicone ear tips
                that ensure a secure fit. Plus, with premium materials and
                durable construction, they're built to withstand the rigors of
                daily use.
              </p>
              <p>
                <b>Seamless Connectivity:</b> Say goodbye to tangled wires and
                complicated setups. Our earbuds offer seamless Bluetooth
                connectivity, allowing you to pair effortlessly with your
                devices and enjoy wireless freedom.
              </p>
              <p>
                <b>Long-Lasting Battery Life:</b> Whether you're commuting,
                working out, or just relaxing at home, our earbuds provide hours
                of uninterrupted listening pleasure on a single charge, so you
                can keep the music playing all day long.
              </p>
              <p>
                <b>Sleek and Stylish Design:</b> With sleek, modern designs and
                a range of stylish colors to choose from, our earbuds are as
                fashionable as they are functional, making a statement wherever
                you go.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid row d-none d-md-block  d-md-flex justify-content-center mt-5 px-5">
        <div className="col-12 col-md-6 pt-5 px-5">
          <p className="  fs-5 text-left">
            Balaji Manufacturing Company India stands as a prominent
            manufacturer of earbuds accessories and electronic products, driven
            by the steadfast vision of becoming a Make in India-Make for World
            brand. Specializing in premium earbuds alongside a diverse range of
            mobile accessories such as earphones, neckbands, wireless earbuds,
            mobile chargers, and more, our company ensures top-notch quality
            products at accessible prices. Situated in Ahemedabad, with a
            state-of-the-art manufacturing unit in Gujarat, Balaji Manufacturing
            Company India epitomizes innovation and excellence in the industry.
            Our motive is to give you your own personal brand. Come and Join Us
            Our Company - <b>Balaji Manufacturing Company</b>.
          </p>
        </div>
        <div className="col-12 col-md-6 px-5">
          <img
            src="./Images/aboutimg1.png"
            className="img-fluid"
            height="500px"
            alt="/aboutimg1"
          />
        </div>
      </div>

      <div className="row  d-sm-block d-md-none d-flex justify-content-center mt-2 p-3">
        <div className="col-12 col-md-6 ">
          <img
            src="./Images/aboutimg1.png"
            className="img-fluid"
            height="500px"
            alt="/aboutimg1"
          />
        </div>
        <div className="col-12 col-md-6 p-3">
          <p className=" text-center fs-5">
            Balaji Manufacturing Company India stands as a prominent
            manufacturer of earbuds accessories and electronic products, driven
            by the steadfast vision of becoming a Make in India-Make for World
            brand. Specializing in premium earbuds alongside a diverse range of
            mobile accessories such as earphones, neckbands, wireless earbuds,
            mobile chargers, and more, our company ensures top-notch quality
            products at accessible prices. Situated in Ahemedabad, with a
            state-of-the-art manufacturing unit in Gujarat, Balaji Manufacturing
            Company India epitomizes innovation and excellence in the industry.
            Our motive is to give you your own personal brand. Come and Join Us
            Our Company - <b>Balaji Manufacturing Company</b>.
          </p>
        </div>
      </div>

      <div className="row bg-danger text-white d-flex justify-content-center mt-5">
        <div className="col-12 col-md-6 p-5">
          <p className="fs-2">OUR VISION</p>
          <p>
            "At Balaji Manufacturing Company, our vision is to be the foremost
            innovator and leader in the realm of audio technology, setting new
            standards for excellence and redefining the way people experience
            sound. We envision a world where our earbuds seamlessly integrate
            into every facet of life, enhancing communication, entertainment,
            and personal wellness. By leveraging advanced research, sustainable
            practices, and a deep understanding of our customers' needs, we aim
            to inspire joy, elevate productivity, and foster meaningful
            connections through the transformative power of immersive audio
            experiences."
          </p>
        </div>
        <div className="col-12 col-md-6 p-5">
          <p className="fs-2">OUR MISSION</p>
          <p>
            {""}
            "At Balaji Manufacturing Company, our mission is to seamlessly blend
            cutting-edge technology with exceptional design to deliver superior
            audio experiences that enrich and empower the lives of our
            customers. Guided by innovation, quality, and sustainability, we
            strive to redefine the boundaries of sound technology while
            fostering a culture of creativity, integrity, and
            customer-centricity. Our commitment is not only to produce
            state-of-the-art earbuds but also to inspire and connect people
            through the transformative power of music and audio."
          </p>
        </div>
      </div>
    </div>
  );
};
export default AboutUs;
