import React, { useState } from "react";
import { Helmet } from "react-helmet";
import "react-whatsapp-widget/dist/index.css";

const Contact = () => {
  <Helmet>
    <meta charset="utf-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <link
      rel="icon"
      href="./Images/balaji.png"
      alt="/balaji"
      type="image/png"
      sizes="48x48"
    />
    <title>
      Get in Touch with earbuds,charger,cables manufacturing company in india.
    </title>
    <meta
      name="description"
      content="Manufacturer of IP15 CABLE, CHARGER - FLASH/MULTI CHARGER 65W,EAR BUDS - B97 offered by Balaji manufacturing company, Ahmedabad, Gujarat"
    />
    <meta
      name="keywords"
      content="earbuds manufacturer|earbuds india|earphone manufacturers in india|best earbud manufacturers|Balaji Manufacturing Company|Ahmedabad|Gujarat|India|Reach Out to Balaji Manufacturing Company|Contact Information for Balaji Manufacturing Company|Contact Details for Balaji Manufacturing Company|Customer Support for Balaji Manufacturing Company|Sales Inquiries for Balaji Manufacturing Company|General Inquiries for Balaji Manufacturing Company|Feedback for Balaji Manufacturing Company|EAR BUDS-B97|EAR BUDS-W901|EAR BUDS-B93"
    />
    <link rel="canonical" href="https://balajimanufacturing.in/contact" />

    <meta property="og:locale" content="en_us" />
    <script type="application/ld+json">
      {`
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": "Balaji Manufacturing Company",
    "image": "https://balajimanufacturing.in/Images/balaji.png",
    "@id": "https://balajimanufacturing.in/",
    "url": "https://balajimanufacturing.in/",
    "telephone": "+91 8733918223",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "E/4 vishwakarma society nr kameshwar school jodhpur cross road satellite Ahmedabad 380058",
      "addressLocality": "Ahmedabad",
      "postalCode": "380058",
      "addressCountry": "IN"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": 23.0275838,
      "longitude": 72.5216844
    },
    "openingHoursSpecification": {
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday"
    ],
    "opens": "00:00",
    "closes": "23:59"
  },
  "sameAs": [
    "https://www.facebook.com/profile.php?id=61559858167984",
    "https://www.instagram.com/balajicompany9"
  ] 
`}
    </script>
  </Helmet>;

  const initialFormData = {
    name: "",
    phone: "",
    subject: "",
    message: "",
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleWhatsAppClick = () => {
    const { name, phone, subject, message } = formData;
    const whatsappMessage = `
    Name: ${name} 
    Phone: ${phone} 
    Subject: ${subject} 
    Message: ${message}`;
    const whatsappLink = `https://wa.me/+918733918223?text=${encodeURIComponent(
      whatsappMessage
    )}`;
    window.open(whatsappLink, "_blank");
  };
  return (
    <div className="">
      <div className="earbudsimg">
        <img
          src="./Images/bannercontact.jpeg"
          alt="/bannercontact"
          width="100%"
          height="650px"
        />
      </div>
      <div className="container-fluid">
        <div
          className="section custom-bg mt-lg-0 mb-0 py-6 d-flex justify-content-center align-items-center "
          style={{ customBg: "#f3ece4", height: "auto" }}
        >
          <div className="container py-lg-4 ">
            <div className="row m-0 col-mb-50 d-flex justify-content-center">
              <div
                className="col-md-3 bg-light p-5 mb-4 mx-3"
                style={{
                  boxShadow: "5px 5px 5px lightgrey",
                  height: "300px",
                  width: "400px",
                }}
              >
                <div className="feature-box fbox-center fbox-dark fbox-plain ">
                  <div className="fbox-content text-center">
                    <a
                      href="https://www.google.com/maps?q=E%2F4+vishwakarma+society+nr+kameshwar+school+jodhpur+cross+road+satellite+Ahmedabad+380058"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <i className="fa-solid fa-location-dot px-2 fs-4"></i>
                      <p className="text-transform-none fw-medium h4 mt-2">
                        <b>Address</b>
                      </p>
                      <p className="op-06">
                        E/4 vishwakarma society nr kameshwar school jodhpur
                        cross road satellite Ahmedabad 380058
                      </p>
                    </a>
                  </div>
                </div>
              </div>

              <div
                className="col-md-3 bg-light p-5 mb-4 mx-3"
                style={{
                  boxShadow: "5px 5px 5px lightgrey",
                  height: "300px",
                  width: "400px",
                }}
              >
                <div className="feature-box fbox-center fbox-dark fbox-plain ">
                  <div className="fbox-content text-center">
                    <a
                      href="mailto:bmanufacturingcompany@gmail.com"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <i className="fa-solid fa-envelope px-2 fs-4"></i>
                      <p className="text-transform-none fw-medium h4 mt-2">
                        <b>Email</b>
                      </p>
                      <p className="op-06 d-flex justify-content-center">
                        For Any Query
                        <br />
                        You Can Mail us on
                        <br />
                        bmanufacturingcompany@gmail.com
                      </p>
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="col-md-3 bg-light p-5 mb-4 mx-3"
                style={{
                  boxShadow: "5px 5px 5px lightgrey",
                  height: "300px",
                  width: "400px",
                }}
              >
                <div className="feature-box fbox-center fbox-dark fbox-plain ">
                  <div className="fbox-content text-center">
                    <a
                      href="tel:+918733918223"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <i className="fa-solid fa-phone px-2 fs-4"></i>
                      <p className="text-transform-none fw-medium h4 mt-2">
                        <b>Phone</b>
                      </p>
                      <p className="op-06">
                        Contact @<br />
                        +91 8733918223
                      </p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row align-items-center custom-bg">
          <div className="col-md-6  min-vh-50 min-vh-lg-100 d-flex align-self-stretch">
            <div style={{ width: "100%", marginTop: "15px" }}>
              <iframe
                title="/iframe"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14687.585262403114!2d72.5265553!3d23.0275789!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e85bd5d8dfd77%3A0xead4ce15c127bb27!2sBalaji%20Manufacturing%20Company!5e0!3m2!1sen!2sin!4v1717996945205!5m2!1sen!2sin"
                width="100%"
                height="450"
                style={{ border: "0" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>

            <div className="position-absolute pos-x-center pos-y-center m-0 text-white-50">
              Map loading...
            </div>
          </div>

          <div
            className="col-md-6 p-5 p-lg-6 dark text-white"
            style={{ height: "auto" }}
          >
            <form onSubmit={handleWhatsAppClick}>
              <div className="row">
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Your name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Your Phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Your Subject"
                    name="subject"
                    value={formData.subject}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <textarea
                    className="form-control"
                    placeholder="Your Message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="submit"
                  className="p-2 bg-danger border-0 text-light rounded mt-3 "
                >
                  Send Message
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
