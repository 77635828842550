import React from "react";

const Footer = () => {
  return (
    <div>
      <footer className="text-center text-lg-start bg-body-tertiary ">
        <section className="p-2" style={{ backgroundColor: "#eff4f7" }}>
          <div className="container text-center text-md-start mt-5">
            <div className="row mt-3">
              <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                <p
                  className="text-uppercase fw-bold  mb-4"
                  style={{ fontSize: "20px", color: "dark" }}
                >
                  <b>Balaji manufacturing co.</b>
                </p>
                <p className="px-5 px-md-0">
                  "Welcome to <b>BALAJI MANUFACTURING COMPANY</b>, where sound
                  meets style in perfect harmony! Elevate your listening
                  experience with our custom-designed earbuds, crafted to suit
                  your unique tastes and preferences.
                </p>
                <div className="input-group rounded d-flex justify-content-center justify-content-md-start ">
                  <img src="./Images/makein.png" alt="/makein" height="100px" />
                  {/* <button className='p-2 px-5 bg-danger border-0 text-white fs-4 ' onClick={() => handlePhoneClick('+ +91 8733918223')}>Call Now</button> */}
                </div>
              </div>

              <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                <p className="text-uppercase fw-bold mb-4 fs-6">Products</p>
                <p>
                  <a
                    href="/"
                    className="text-reset"
                    style={{ textDecoration: "none" }}
                  >
                    Home
                  </a>
                </p>
                <p>
                  <a
                    href="/about-us"
                    className="text-reset"
                    style={{ textDecoration: "none" }}
                  >
                    About Us
                  </a>
                </p>
                <p>
                  <a
                    href="/earbuds"
                    className="text-reset"
                    style={{ textDecoration: "none" }}
                  >
                    Categories
                  </a>
                </p>
                <p>
                  <a
                    href="/CorporateOrder"
                    className="text-reset"
                    style={{ textDecoration: "none" }}
                  >
                    Corporates Orders
                  </a>
                </p>

                <p>
                  <a
                    href="/contact "
                    className="text-reset"
                    style={{ textDecoration: "none" }}
                  >
                    Contact
                  </a>
                </p>
              </div>

              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-5 d-none d-md-block">
                <p className="text-uppercase fw-bold mb-4 fs-6">Contact</p>
                <div className="d-flex  gap-3">
                  <a
                    href="https://www.google.com/maps?q=E%2F4+vishwakarma+society+nr+kameshwar+school+jodhpur+cross+road+satellite+Ahmedabad+380058"
                    style={{ textDecoration: "none", color: "black" }}
                    title="location"
                    target="_blank"
                    rel="noreferrer"
                    className="d-flex gap-2"
                  >
                    <i className="fa-solid fa-location-dot mt-1"></i>
                    <p>
                      E/4 vishwakarma society nr kameshwar school jodhpur cross
                      road satellite Ahmedabad 380058
                    </p>
                  </a>
                </div>

                <div className="d-flex  gap-3 mt-2">
                  <a
                    href="mailto:bmanufacturingcompany@gmail.com"
                    style={{ textDecoration: "none", color: "black" }}
                    title="mailto"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p className="d-flex gap-2">
                      <i className="fa-solid fa-envelope py-1"></i>{" "}
                      bmanufacturingcompany@gmail.com
                    </p>
                  </a>
                </div>
                <div className="d-flex  gap-3 mt-2">
                  <a
                    href="tel:+91 8733918223"
                    className="text-decoration-none text-dark d-flex"
                    title="phone"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p className="d-flex gap-2 mt-1">
                      <i className="fa-solid fa-phone "></i> +91 8733918223
                    </p>
                  </a>
                </div>

                <div className=" mt-1">
                  <a
                    href="tel:+91 8733918223"
                    className="text-decoration-none text-dark d-flex"
                    title="phone"
                    target="_blank"
                    rel="noreferrer"
                  ></a>
                </div>

                <div className="d-flex px-3 mt-3 gap-3">
                  <div
                    className="text-center pt-2"
                    style={{
                      backgroundColor: "#1877F2",
                      height: "40px",
                      width: "40px",
                      borderRadius: "50%",
                    }}
                  >
                    <a
                      href="https://www.facebook.com/profile.php?id=61559858167984"
                      title="facebook"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa-brands fa-facebook-f px-1 fs-5 text-white"></i>
                    </a>
                  </div>

                  <div
                    className="mx-1 text-center pt-2"
                    style={{
                      background:
                        "linear-gradient(45deg, #f9ce34, #ee2a7b, #6228d7)",
                      height: "40px",
                      width: "40px",
                      borderRadius: "50%",
                    }}
                  >
                    <a
                      href="https://www.instagram.com/balajicompany001/"
                      title="instagram"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa-brands fa-instagram px-1 fs-5 text-white"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-5 d-md-none d-sm-block">
                <p className="text-uppercase fw-bold mb-4 fs-6">Contact</p>
                <div className="d-flex justify-content-center gap-3">
                  <a
                    href="https://www.google.com/maps?q=E%2F4+vishwakarma+society+nr+kameshwar+school+jodhpur+cross+road+satellite+Ahmedabad+380058"
                    style={{ textDecoration: "none", color: "black" }}
                    title="location"
                    target="_blank"
                    rel="noreferrer"
                    className="d-flex gap-2"
                  >
                    <i className="fa-solid fa-location-dot mt-1"></i>
                    <p>
                      E/4 vishwakarma society nr kameshwar school jodhpur cross
                      road satellite Ahmedabad 380058
                    </p>
                  </a>
                </div>

                <div className="d-flex justify-content-center gap-3 mt-2">
                  <a
                    href="mailto:bmanufacturingcompany@gmail.com"
                    style={{ textDecoration: "none", color: "black" }}
                    title="mailto"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p className="d-flex gap-2">
                      <i className="fa-solid fa-envelope py-1"></i>{" "}
                      bmanufacturingcompany@gmail.com
                    </p>
                  </a>
                </div>
                <div className="d-flex justify-content-center gap-3 mt-2">
                  <a
                    href="tel:+91 8733918223"
                    className="text-decoration-none text-dark d-flex"
                    title="phone"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p className="d-flex gap-2 mt-1">
                      <i className="fa-solid fa-phone "></i> +91 8733918223
                    </p>
                  </a>
                </div>

                <div className=" mt-1">
                  <a
                    href="tel:+91 8733918223"
                    className="text-decoration-none text-dark d-flex"
                    title="phone"
                    target="_blank"
                    rel="noreferrer"
                  ></a>
                </div>

                <div className="d-flex justify-content-center px-3 mt-3 gap-3">
                  <div
                    className="text-center pt-2"
                    style={{
                      backgroundColor: "#1877F2",
                      height: "40px",
                      width: "40px",
                      borderRadius: "50%",
                    }}
                  >
                    <a
                      href="https://www.facebook.com/profile.php?id=61559858167984"
                      title="facebook"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa-brands fa-facebook-f px-1 fs-5 text-white"></i>
                    </a>
                  </div>

                  <div
                    className="mx-1 text-center pt-2"
                    style={{
                      background:
                        "linear-gradient(45deg, #f9ce34, #ee2a7b, #6228d7)",
                      height: "40px",
                      width: "40px",
                      borderRadius: "50%",
                    }}
                  >
                    <a
                      href="https://www.instagram.com/balajicompany001/"
                      title="instagram"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa-brands fa-instagram px-1 fs-5 text-white"></i>
                    </a>
                  </div>
                </div>
              </div>

              <div
                className="col-md-3 col-lg-3 col-xl-3  mb-md-0 mb-4 "
                style={{ marginTop: "-30px" }}
              >
                <div className="last mx-4">
                  <div className="img-container">
                    <img
                      src="./Images/buds1.png"
                      height="100px"
                      alt="buds1"
                      className="img-with-shadow"
                    />
                    <img
                      src="./Images/buds2.png"
                      height="100px"
                      alt="buds2"
                      className="img-with-shadow"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src="./Images/buds3.png"
                      height="100px"
                      alt="buds3"
                      className="img-with-shadow"
                    />
                    <img
                      src="./Images/buds4.png"
                      height="100px"
                      alt="buds4"
                      className="img-with-shadow"
                    />
                  </div>
                </div>

                <div
                  className="text-end d-none d-md-block mt-5"
                  style={{ marginRight: "20px" }}
                  // style={{ marginTop: "150px", marginLeft: "50px" }}
                >
                  <img
                    src="./Images/ce.png"
                    className="px-1"
                    alt="/ce"
                    height="80px"
                  />
                  <img
                    src="./Images/recycle.png"
                    className="px-2"
                    alt="/recycle"
                    height="60px"
                  />
                  <img
                    src="./Images/BISLogo.png"
                    className="px-2"
                    alt="/BISLogo"
                    height="60px"
                  />
                </div>
              </div>

              <div
                className="text-center  text-md-end mt-md-0 mt-0 d-block d-md-none"
                style={{ marginLeft: "-100px" }}
              >
                <div
                  className="d-flex justify-content-center justify-content-md-end"
                  style={{ marginLeft: "190px" }}
                >
                  <img
                    src="./Images/ce.png"
                    className="px-1"
                    alt="/ce"
                    height="80px"
                  />
                  <img
                    src="./Images/recycle.png"
                    className="px-2"
                    alt="/recycle"
                    height="60px"
                  />
                  <img
                    src="./Images/BISLogo.png"
                    className="px-2"
                    alt="/BISLogo"
                    height="60px"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="bg-dark text-white py-1 p-1 text-center fs-6">
          <p>
            © 2024{" "}
            <span className="text-uppercase">
              Mannix Infotech Solutions Pvt.Ltd.
            </span>{" "}
            - All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
