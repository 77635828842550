import React from "react";
import { useLocation } from "react-router-dom";

const Header = () => {
  const handleWhatsAppClick = () => {
    const phoneNumber = "918733918223";
    const message = "Hello! Can I Get More Information About Your Business!";
    const encodedMessage = encodeURIComponent(message);

    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

    window.open(whatsappUrl, "_blank");
  };
  const location = useLocation();

  // Helper function to check if the current path is a category path
  const isCategoryPath = () => {
    const categoryPaths = ["/earbuds", "/charger", "/cables"];
    return categoryPaths.includes(location.pathname);
  };

  return (
    <div className="sticky-top bg-light">
      <nav
        className="navbar navbar-expand-lg navbar-light"
        style={{ backgroundColor: "#0000", position: "relative" }}
      >
        <div className="container-fluid">
          <div className="d-flex">
            <a className="navbar-brand d-flex" href="/">
              <img
                src="./Images/balaji.png"
                alt="/balaji"
                className="mx-2"
                height="70px"
              />
              <p className="mt-4 webname">
                {" "}
                <b style={{ color: "#2980b9" }}> BALAJI MANUFACTURING CO.</b>
              </p>
            </a>

            <button
              className="navbar-toggler mb-1 "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0 ">
              <li className="nav-item ">
                <a
                  className={`nav-link ${
                    location.pathname === "/" ? "active" : ""
                  }`}
                  aria-current="page"
                  href="/"
                >
                  HOME
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    location.pathname === "/about-us" ? "active" : ""
                  }`}
                  aria-current="page"
                  href="/about-us"
                >
                  ABOUT US
                </a>
              </li>
              <li className="nav-item dropdown">
                <a
                  className={`nav-link ${isCategoryPath() ? "active" : ""}`}
                  href="/"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  CATEGORIES{" "}
                  <svg
                    xmlns="https://www.w3.org/2000/svg"
                    width="16"
                    height="13"
                    fill="currentColor"
                    className="bi bi-chevron-down"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                    />
                  </svg>
                </a>

                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdown"
                  style={{ position: "absolute", marginTop: "22px" }}
                >
                  <li className="d-flex p-3">
                    <a className="dropdown-item" href="/earbuds">
                      <img
                        src="./Images/earbuds.png"
                        height="40px"
                        alt="/earbuds"
                      />{" "}
                      Ear Buds
                    </a>
                  </li>
                  <li className="d-flex p-3">
                    <a className="dropdown-item" href="/charger">
                      <img
                        src="./Images/bgremovecharger.png"
                        height="40px"
                        alt="/bgremovecharger"
                      />{" "}
                      Charger
                    </a>
                  </li>

                  <li className="d-flex p-3">
                    <a className="dropdown-item" href="/cables">
                      <img
                        src="./Images/cables.png"
                        height="40px"
                        alt="/cables"
                      />{" "}
                      Cables
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    location.pathname === "/CorporateOrder" ? "active" : ""
                  }`}
                  href="/CorporateOrder"
                >
                  CORPORATE ORDERS
                </a>
              </li>

              <li className="nav-item">
                <a
                  className={`nav-link ${
                    location.pathname === "/contact" ? "active" : ""
                  }`}
                  href="/contact"
                >
                  CONTACT
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <div
        className="sticky sticky-icon d-none d-md-block"
        style={{ zIndex: 2, width: "1px", top: "90%", right: "70px" }}
      >
        <a
          href="https://whatsapp.com/.com/"
          className="Watshapp big"
          target="_blank"
          rel="noreferrer"
          onClick={handleWhatsAppClick}
        >
          <i
            className="fa-brands fa-whatsapp big "
            style={{ fontSize: "30px" }}
          >
            {" "}
          </i>{" "}
        </a>
      </div>

      <div
        className="sticky-icon d-md-none d-sm-block position-fixed"
        style={{ zIndex: 2, width: "210px", bottom: "3%", right: "30px" }} // Adjust bottom and right values as needed
      >
        <a
          href="https://whatsapp.com/"
          className="Watshapp "
          target="_blank"
          rel="noreferrer"
          onClick={handleWhatsAppClick}
        >
          <i
            className="fa-brands fa-whatsapp pt-1 small"
            style={{ fontSize: "40px" }} // WhatsApp green color
          >
            {" "}
          </i>
        </a>
      </div>
    </div>
  );
};

export default Header;
