import React from "react";

const PageNotFound = () => {
  return (
    <div className="d-flex justify-content-center mt-5">
      <img src="./Images/notfound.jpg" alt="/notfound" />
    </div>
  );
};

export default PageNotFound;
