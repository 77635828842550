import React from "react";

const NewLaunches = ({ newproduct }) => {
  const handleWhatsAppClick = () => {
    const phoneNumber = "918733918223";
    const message = "Hello! Can I Get More Information About Your Business!";
    const encodedMessage = encodeURIComponent(message);

    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

    window.location.href = whatsappUrl;
  };
  return (
    <div className="container-fluid p-5">
      <div className="p-2">
        <h1 className="text-dark" style={{ fontSize: "24px" }}>
          New{" "}
          <span>
            <b>
              Arriva
              <span className="border-bottom  border-danger border-5">ls</span>
            </b>
          </span>
        </h1>
      </div>
      <div className="row justify-content-center">
        {newproduct.map((item, index) => (
          <div key={index} className="col-lg-4 col-md-4 col-sm-6 col-12 mb-3">
            <div
              className="card bg-light border border-0"
              style={{ boxShadow: "0px 0px 5px grey" }}
            >
              <img
                src={item.img}
                className="card-img-top zoom"
                alt="Product"
                height="300px"
              />
              <div className="card-body text-center">
                <h2 className="card-title fs-5">{item.name}</h2>

                <button
                  className="btn btn-danger btn-block"
                  onClick={handleWhatsAppClick}
                >
                  Shop Now
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NewLaunches;
