import React from "react";
import { Helmet } from "react-helmet";

const Charger = ({ charger }) => {
  <Helmet>
    <meta charset="utf-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <link
      rel="icon"
      href="./Images/balaji.png"
      alt="/balaji"
      type="image/png"
      sizes="48x48"
    />
    <title>Best charger manufacturer company in Gujarat,India</title>
    <meta
      name="description"
      content="Manufacturer of Charger - CHARGER - FLASH/MULTI CHARGER 65W,CHARGER - FLASH/MULTI CHARGER 65W,CAR CHARGER PD+QC offered by Blaji manufacturer comapy, Ahmedabad, Gujarat."
    />
    <meta
      name="keywords"
      content="earbuds manufacturer|earbuds india|earphone manufacturers in india|best earbud manufacturers|Best Charger manufacturer company in Ahmedabad|Gujarat|India|Charger manufacturer company in Ahmedabad|Gujarat|Best Charger manufacturer in Ahmedabad|CHARGER-FLASH/MULTI CHARGER 65W|CAR CHARGER PD+QC|Manufacturer company in Ahmedabad|Balaji manufacturer company in Ahmedabad|manufacturer Chargers in Gujarat|List of charger manufacturer company in gujarat"
    />
    <link rel="canonical" href="https://balajimanufacturing.in/charger" />

    <meta property="og:locale" content="en_us" />
    <script type="application/ld+json">
      {`
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": "Balaji Manufacturing Company",
    "image": "https://balajimanufacturing.in/Images/balaji.png",
    "@id": "https://balajimanufacturing.in/",
    "url": "https://balajimanufacturing.in/",
    "telephone": "+91 8733918223",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "E/4 vishwakarma society nr kameshwar school jodhpur cross road satellite Ahmedabad 380058",
      "addressLocality": "Ahmedabad",
      "postalCode": "380058",
      "addressCountry": "IN"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": 23.0275838,
      "longitude": 72.5216844
    },
    "openingHoursSpecification": {
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday"
    ],
    "opens": "00:00",
    "closes": "23:59"
  },
  "sameAs": [
    "https://www.facebook.com/profile.php?id=61559858167984",
    "https://www.instagram.com/balajicompany9"
  ] 
`}
    </script>
  </Helmet>;

  const handleWhatsAppClick = () => {
    const phoneNumber = "918733918223";
    const message = "Hello! Can I Get More Information About Your Business!";
    const encodedMessage = encodeURIComponent(message);

    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

    window.location.href = whatsappUrl;
  };

  return (
    <div>
      <div className="earbudsimg">
        <img
          src="./Images/bannercontact.jpeg"
          alt="/bannercontact"
          width="100%"
          height="650px"
        />
      </div>

      <div className="p-5">
        <div className="d-flex justify-content-between  flex-wrap align-items-center">
          <div>
            <h1 className="text-dark" style={{ fontSize: "28px" }}>
              <span>
                Char
                <span className="border-bottom  border-danger border-5">
                  <b>ger</b>
                </span>{" "}
              </span>
            </h1>
          </div>
        </div>

        <div className="row justify-content-center">
          {charger.map((item, index) => (
            <div
              key={index}
              className=" col-lg-3 col-md-4 col-sm-6 col-12 mb-3"
            >
              <div className="card bg-white border border-solid">
                <img
                  src={item.img}
                  height="250px"
                  className=""
                  alt={item.alt}
                />
                <div className="card-body text-center">
                  <p className="card-title mt-0 fs-5">{item.name}</p>
                  <button
                    className="best btn border border-danger btn w-100 mt-2"
                    onClick={handleWhatsAppClick}
                    style={{ outline: "none" }}
                  >
                    Shop Now
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Charger;
