import "./App.css";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CorporatesOrders from "./Screen/CorporatesOrders";
import Contact from "./Screen/Contact";
import Home from "./Screen/Home";
import Cables from "./Screen/Categories/Cables";
import EarBoat from "./Screen/Categories/EarBoat";
import Data from "./Data/data.json";
import NewLaunches from "./Screen/new-launches";
import Charger from "./Screen/Categories/Charger";
import AboutUs from "./Screen/AboutUs";
import "bootstrap/dist/css/bootstrap.min.css";

import "bootstrap";
import "@popperjs/core";
import PageNotFound from "./Screen/PageNotFound";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes path="/">
          <Route
            path="/"
            element={
              <>
                <Header />
                <Home />
                <Footer />
              </>
            }
          />
          <Route
            path="/about-us"
            element={
              <>
                <Header /> <AboutUs />
                <Footer />
              </>
            }
          />
          {/* <Route
            path="/category"
            element={
              <>
                <Header />
                <Categories />
                <Footer />
              </>
            }
          /> */}
          <Route
            path="/charger"
            element={
              <>
                <Header />
                <Charger charger={Data.Charger} />
                <Footer />
              </>
            }
          />
          <Route
            path="/earbuds"
            element={
              <>
                <Header />
                <EarBoat earbuds={Data.EarBuds} />
                <Footer />
              </>
            }
          />
          <Route
            path="/cables"
            element={
              <>
                <Header />
                <Cables cable={Data.Cable} />
                <Footer />
              </>
            }
          />
          <Route
            path="/CorporateOrder"
            element={
              <>
                <Header />
                <CorporatesOrders CorporateOrder={Data.Orders} /> <Footer />
              </>
            }
          />
          <Route
            path="/contact"
            element={
              <>
                <Header />
                <Contact />
                <Footer />
              </>
            }
          />
          <Route
            path="/new-launches"
            element={
              <>
                <Header />
                <NewLaunches newproduct={Data.NewProduct} />
                <Footer />
              </>
            }
          />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
