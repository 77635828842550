import React, { useEffect, useRef, useState } from "react";
import NewLounch from "./NewLounch";
import Data from "../Data/data.json";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Home = () => {
  const initialFormData = {
    name: "",
    phone: "",
    Comapnyname: "",
    Address: "",
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleWhatsAppClick = () => {
    const { name, Comapnyname, phone, Address } = formData;
    const whatsappMessage = `
    Name: ${name}
    Phone: ${phone}
    Comapnyname: ${Comapnyname}
    Address: ${Address}`;
    const whatsappLink = `https://wa.me/+918733918223?text=${encodeURIComponent(
      whatsappMessage
    )}`;
    window.open(whatsappLink, "_blank");
  };

  const [showForm, setShowForm] = useState(false);
  const formRef = useRef(null);

  const handleSubmitRequirement = () => {
    setShowForm(true);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (formRef.current && !formRef.current.contains(event.target)) {
        setShowForm(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div>
      <Helmet>
        <meta charset="utf-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          rel="icon"
          href="./Images/balaji.png"
          alt="/balaji"
          type="image/png"
          sizes="48x48"
        />
        <title>
          Wireless Earbuds - TWS Earbuds Manufacturer from India | OEM/ODM
          Earbuds Manufacturer in India
        </title>
        <meta
          name="description"
          content="Balaji manufacturing Company - Earbuds, Cables & Charger Manufacturer from Ahmedabad, Gujarat, India"
        />
        <meta
          name="keywords"
          content="earbuds manufacturer|earbuds india|earphone manufacturers in india|best earbud manufacturers|Earbuds Customize Balaji manufacturer company in Ahmedabad|Gujarat|India|Best Earbuds manufacturer company in Ahmedabad|Cables manufacturer company near satellite|Charger manufacturer company in Ahmedabad|EAR BUDS-W901|EAR BUDS-B93|IP15 CABLE|BRAIDED CABLE 6A 85W|CHARGER-FLASH/MULTI CHARGER 65W|CHARGER|FLASH/MULTI CHARGER 65W
"
        />
        <link rel="canonical" href="https://balajimanufacturing.in/" />

        <meta property="og:locale" content="en_us" />
        <script type="application/ld+json">
          {`
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": "Balaji Manufacturing Company",
    "image": "https://balajimanufacturing.in/Images/balaji.png",
    "@id": "https://balajimanufacturing.in/",
    "url": "https://balajimanufacturing.in/",
    "telephone": "+91 8733918223",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "E/4 vishwakarma society nr kameshwar school jodhpur cross road satellite Ahmedabad 380058",
      "addressLocality": "Ahmedabad",
      "postalCode": "380058",
      "addressCountry": "IN"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": 23.0275838,
      "longitude": 72.5216844
    },
    "openingHoursSpecification": {
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday"
    ],
    "opens": "00:00",
    "closes": "23:59"
  },
  "sameAs": [
    "https://www.facebook.com/profile.php?id=61559858167984",
    "https://www.instagram.com/balajicompany9"
  ] 
`}
        </script>
      </Helmet>

      <div className="col-12 w-100">
        <div className="video-container p-0">
          <video
            controls
            autoPlay
            loop
            muted
            playsInline
            preload="metadata"
            style={{ pointerEvents: "none" }}
          >
            <source src="./Images/Mainvideo.mp4" type="video/mp4" />
          </video>
        </div>
      </div>

      <div className="container mt-5 ">
        <div className="text-left">
          <h1 className="fs-5 mb-3">
            <i className="fa-solid fa-check text-success"></i>&nbsp;
            <b>
              <b className="text-danger">TWS WIRELESS EARBUDS:</b>MAKE YOUR
              GURANTED OEM/ODM EARBUDS WITH YOUR BRAND LOGO.
            </b>
          </h1>
          <h2 className="fs-5 mb-3">
            <i className="fa-solid fa-check text-success"></i>&nbsp;
            <b>
              <b className="text-danger">MOBILE PHONE CHARGING DATA CABLE:</b>
              MAKE YOUR GURANTED OEM/ODM CABLE WITH YOUR BRAND LOGO.
            </b>
          </h2>
          <h3 className="fs-5 ">
            <i className="fa-solid fa-check text-success"></i>&nbsp;
            <b>
              <b className="text-danger">MOBILE PHONE CHARGER:</b>MAKE YOUR
              GURANTED OEM/ODM EARBUDS WITH YOUR BRAND LOGO.
            </b>
          </h3>
          <div className="px-3">
            <p className="mt-4">
              <b>BALAJI MANUFACTURING COMPANY</b> is a <b>manufacturer</b> of
              100% quality products in Mobile Phone Accessories, Bluetooth
              Earbuds, USB Data Cable & Charger. Bluetooth Earbuds easy &
              comfortable wearable.Our most focus in OEM [Original Equipment
              Manufacturing] & ODM [Original Design Manufacturing] of
              state-of-the-<b>manufacturer</b> Earbuds and High Capacity Charger
              & Cables. Our Manufacturing unit is Situated in Ahemedabad,
              Gujarat, India.
            </p>
          </div>
        </div>
      </div>

      <div className="container mt-1 px-4">
        <div className="text-left">
          <p className="mt-4">
            Our products quality is 100% trustable, and the price is economical.
            No Worries about product quality & services. because we are dealing
            in this field <b>Since, 2010</b>, Always we care about quality,
            price & services.
          </p>
          <p className="text-danger fs-5">
            <b>WHY BALAJI MANUFACTURING COMPANY IS THE 1ST CHOICE?</b>
          </p>
          <ul style={{ fontSize: "18px" }}>
            <li>We follow all Technical Parameters in Manufacturing.</li>
            <li>
              We manufacturers 100% quality products with seeing every safety
              parameters <b>like</b>:Battery quality, PCB quality, etc.
            </li>
            <li>
              We make it easy for you to get the service.you need Your Time is
              Valuable. Let us Handle it with ease.
            </li>
            <li>Customised Your Brand's Logo.</li>
            <li>Small Orders also accepted. We Cater to all needs.</li>
            <li>Our products are very comfortable to operate and wearing.</li>
            <li>Our Products have a wide range of functionality.</li>
          </ul>
        </div>
      </div>
      <div>
        <div className="col-12 d-md-flex justify-content-md-center p-4 mt-1 d-none d-md-block">
          <div
            className="col-md-3 p-3 bg-danger text-center m-2    "
            style={{ borderRight: "1px solid white" }}
          >
            <Link
              to="/earbuds"
              className=" text-white border-0 fs-5"
              style={{ textDecoration: "none" }}
            >
              Ear Buds
            </Link>
          </div>
          <div
            className="col-md-3 p-3 bg-danger text-center m-2  "
            style={{ borderRight: "1px solid white" }}
          >
            <Link
              to="/charger"
              className=" text-white border-0 fs-5"
              style={{ textDecoration: "none" }}
            >
              Chargers
            </Link>
          </div>
          <div
            className="col-md-3 p-3 bg-danger text-center m-2  "
            style={{ borderRight: "1px solid white" }}
          >
            <Link
              to="/cables"
              className=" text-white border-0 fs-5"
              style={{ textDecoration: "none" }}
            >
              Cables
            </Link>
          </div>
          <div className="col-md-3  p-3 bg-danger text-center m-2  ">
            <Link
              to="/new-launches"
              className=" text-white border-0 fs-5"
              style={{ textDecoration: "none" }}
            >
              New Arrivals
            </Link>
          </div>
        </div>

        <div
          className="col-12 d-block d-md-none d-flex flex-wrap px-4 forcircle"
          style={{}}
        >
          <div
            className="col-md-3 d-flex align-items-center justify-content-center p-3 text-dark m-2 rounded-circle"
            style={{
              width: "145px",
              height: "145px",
              backgroundImage: "url(./Images/img61.png)",
              opacity: "0.7",
              backgroundSize: "cover",
              backgroundPosition: "center",
              boxShadow:
                "rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px",
            }}
          >
            <Link
              to="/earbuds"
              className="text-white fw-bold border-0 "
              style={{ textDecoration: "none", fontSize: "25px" }}
            >
              EAR BUD
            </Link>
          </div>
          <div
            className="col-md-3 d-flex align-items-center justify-content-center p-3 text-dark m-2 rounded-circle"
            style={{
              width: "145px",
              height: "145px",
              backgroundImage: "url(./Images/charger.png)",
              opacity: "0.7",
              backgroundSize: "cover",
              backgroundPosition: "center",
              boxShadow:
                "rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px",
            }}
          >
            <Link
              to="/charger"
              className="text-dark fw-bold border-0 "
              style={{ textDecoration: "none", fontSize: "25px" }}
            >
              CHRAGER
            </Link>
          </div>

          <div
            className="col-md-3 d-flex align-items-center justify-content-center p-3 text-dark m-2 rounded-circle"
            style={{
              width: "145px",
              height: "145px",
              backgroundImage: "url(./Images/cable.png)",
              alt: "/cable",
              opacity: "0.7",
              backgroundSize: "cover",
              backgroundPosition: "center",
              boxShadow:
                "rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px",
            }}
          >
            <Link
              to="/cables"
              className="text-dark fw-bold border-0 "
              style={{ textDecoration: "none", fontSize: "25px" }}
            >
              CABLES
            </Link>
          </div>
          <div
            className="col-md-3 d-flex align-items-center justify-content-center p-3 text-dark m-2 rounded-circle"
            style={{
              width: "155px",
              height: "155px",
              backgroundImage: "url(./Images/newarriaval.png)",
              alt: "/newarriaval",
              opacity: "0.7",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <Link
              to="/new-launches"
              className="text-white border-0 "
              style={{ textDecoration: "none" }}
            >
              <img
                src="./Images/newarriaval.png"
                alt="/newarriaval"
                width="155px"
                height="155px"
              />
            </Link>
          </div>
        </div>

        <div className="bg-warning p-5 text-center position-relative">
          <p className=" fw-bold fs-3" style={{ wordSpacing: "2px" }}>
            CONNECT WITH US FOR A FREE SAMPLE
          </p>
          <div className="d-flex justify-content-center gap-3">
            <a
              rel="noreferrer"
              href="tel:+918733918223"
              style={{ textDecoration: "none", color: "black" }}
              target="_blank"
            >
              <button
                className="px-5 p-3 border-0 fw-bold text-white fs-5"
                style={{ backgroundColor: "#dc3545" }}
              >
                Call Now
              </button>
            </a>

            <button
              className="px-5 p-2 border-0 fw-bold text-white fs-5"
              style={{ backgroundColor: "#dc3545", position: "relative" }}
              onClick={handleSubmitRequirement}
            >
              Whatsapp Now
            </button>
          </div>
        </div>

        {showForm && (
          <div
            ref={formRef}
            className="position-absolute start-50 translate-middle bg-white p-5 shadow"
            style={{ zIndex: 2, width: "300px" }}
          >
            <form onSubmit={handleWhatsAppClick}>
              <div className="row">
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Your name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Comapny name"
                    name="Comapnyname"
                    value={formData.Comapnyname}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Contact Number"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Company Address"
                    name="Address"
                    value={formData.Address}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div className="d-flex justify-content-center">
                <button
                  type="submit"
                  className="p-2 bg-danger border-0 text-light rounded"
                >
                  Send Message
                </button>
              </div>
            </form>
          </div>
        )}
      </div>

      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 d-flex align-items-center mt-5">
            <video
              src="./Images/video1.mp4"
              autoPlay
              controls
              muted
              loop
              className="img-fluid"
              alt="/video1"
              playsInline
              style={{ height: "100%", width: "100%", pointerEvents: "none" }}
            />
          </div>
          <div className="col-12 col-md-6 mt-5">
            <img
              src="./Images/imges.png"
              className="img-fluid homepic"
              alt="/imges"
              style={{ height: "360px", width: "750px" }}
            />
          </div>
        </div>
        <div className="row ">
          <div className="col-12 col-md-6 mt-5">
            <img
              src="./Images/images2.jpg"
              className="img-fluid homepic"
              alt="/images2"
              style={{ height: "360px", width: "750px" }}
            />
          </div>
          <div className="col-12 col-md-6 d-flex align-items-center mt-5">
            <img
              src="./Images/bgimg.jpeg"
              className="img-fluid homepic"
              alt="/bgimg"
              style={{ height: "360px", width: "750px" }}
            />
          </div>
        </div>
      </div>

      <NewLounch newlounch={Data.NewLounch} />
    </div>
  );
};

export default Home;
